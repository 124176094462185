import React from 'react'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Layout from '../../../components/Default/Layout'
import ServiceHeader from '../../../components/Services/ServiceOverview/ServiceHeader'
import './styles.css'
import Accordion from '../../../components/Accordion/Accordion'
import Index from './../../../../content/accordion/udalosti'
import { color, colorStyle } from 'styled-system'

export const UplynuleUdalosti = () => {
  return (
    <div>
      <br />

      {Index.uplynule.map(item => (
        <div className="PrednaskyKonference--Link">
          <a
          // target="_blank"
          // rel="noopener noreferrer"
          // href={item.link ? item.link : ''}
          >
            {item.title}
          </a>
        </div>
      ))}
    </div>
  )
}

export const BudouciUdalosti = () => {
  return (
    <div>
      <br />
      {Index.budouci.map(item => (
        <div className="PrednaskyKonference--Link">
          <a
          // target="_blank"
          // rel="noopener noreferrer"
          // href={item.link ? item.link : ''}
          >
            {item.title}
          </a>
        </div>
      ))}
    </div>
  )
}

const PrednaskyAKonference = ({ data: { page }, pageContext }) => {
  const { image, title } = page.frontmatter
  const {
    breadcrumb: { crumbs }
  } = pageContext
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
      metaCrumb={crumbs}
    >
      <ServiceHeader
        title={title}
        backgroundImage={
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        }
      />
      <Breadcrumb crumbs={crumbs} crumbLabel={'Přednášky a konference'} />

      {/* ------------------ 1. přednáška ------------------ */}

      <div className="PrednaskyKonference--Wrapper container">
        <div className="row">
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <h2>DPH u pronájmu nemovitostí</h2>
            <p>
              <b>Link: </b>
              <a
                className="PrednaskyKonference--Link"
                href="https://vox.cz/kurzy/dph-u-pronajmu-nemovitosti-3/?termin=340892"
                target="_blank"
              >
                Školení DPH u pronájmu nemovitostí – Vzdělávání VOX
              </a>
            </p>
            <p>
              <b>Termín: </b>07.05.2024 / 09.00–12.00 / seminář
            </p>
            <p>
              <p>
                <b>Lektor: </b>Jan Kotala, Jan Tecl
              </p>
              <div className="PrednaskyLektor wrapper">
                <div>
                  <b>Organizátor: </b>
                  <img
                    className="PartnerLogo"
                    src="/images/konference/partners/vox.png"
                  ></img>
                </div>
              </div>
              <div>
                <p>
                  Využijte{' '}
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    slevu ve výši 20 %
                  </span>{' '}
                  ze základní ceny bez DPH na tento seminář. Doplňte heslo{' '}
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    „EKP“
                  </span>{' '}
                  do pole „Chci přidat důležitou poznámku“ v e-shopu při
                  objednávání.
                </p>
              </div>
            </p>

            {/* ZDE */}
          </div>
          <div className="PrednaskyLektor col-xl-2 col-sm-2 col-12 wrapper">
            <img src="/images/konference/lectors/jan_kotala.png"></img>
            <img src="/images/konference/lectors/jan_tecl.png"></img>
          </div>
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <p>
              <b>Anotace:</b>
            </p>
            <p>
              Seminář je určen zejména vlastníkům nemovitostí, pronajímatelům,
              developerům, jejich účetním a daňovým pracovníkům, dále daňovým
              poradcům, auditorům…
              <li>jaké typy nájmu mohou být aktuálně s DPH?,</li>
              <li>
                jak postupovat v případě rozestavěné budovy nebo v
                rekonstruované budově?,
              </li>
              <li>jaký pohled má Finanční správa?,</li>
              <li>které nárokované DPH je třeba „hlídat“?,</li>
              <li>jaký je správný postup při vracení dříve nárokované DPH?,</li>
              <li>jakým způsobem je možné eliminovat negativní vlivy?,</li>
              <li>
                jakým způsobem přistupovat z pohledu DPH k platbám za elektřinu
                / vodu?,
              </li>
              <li>
                nejčastější chyby v oblasti nemovitých věcí z pohledu DPH a
                DPPO.
              </li>
            </p>
          </div>
        </div>
      </div>

      {/* --------------- konec 1. přednáška --------------- */}

      {/* ------------------ 2. přednáška ------------------ */}

      <div className="PrednaskyKonference--Wrapper container">
        <div className="row">
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <h2>Global Ecommerce Congress</h2>
            <p>
              <b>Link: </b>
              <a
                className="PrednaskyKonference--Link"
                href="https://www.gecomcon.com/cs/"
                target="_blank"
              >
                GECOM - GECOM NOC
              </a>
            </p>

            <p>
              <b>Termín: </b>28.05.2024 / 09.00 – 16.00 / konference
            </p>
            <p>
              <p>
                <b>Lektor: </b>Jan Tecl, Lukáš Eisenwort
              </p>
              <div className="PrednaskyLektor wrapper">
                <div>
                  <b>Organizátor: </b>
                  <img
                    className="PartnerLogo"
                    src="/images/konference/partners/gecom.png"
                  ></img>
                </div>
              </div>
            </p>

            {/* ZDE */}
          </div>
          <div className="PrednaskyLektor col-xl-2 col-sm-2 col-12 wrapper">
            <img src="/images/konference/lectors/lukas_eisenwort.png"></img>
            <img src="/images/konference/lectors/jan_tecl.png"></img>
          </div>
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <p>
              <b>Anotace:</b>
            </p>
            <p>
              Program GECOMu je event s mnoha zajímavými tématy z oblasti
              e-commerce a povedou ho špičky z oboru.
            </p>
          </div>
        </div>
      </div>

      {/* --------------- konec 2. přednáška --------------- */}

      {/* ------------------ 3. přednáška ------------------ */}

      <div className="PrednaskyKonference--Wrapper container">
        <div className="row">
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <h2>
              Převodní ceny v ČR aktuálně a pro rok 2023 – přístup finanční
              správy a rozsah dokumentace
            </h2>
            <p>
              <b>Link: </b>
              <a
                className="PrednaskyKonference--Link"
                href="https://vox.cz/kurzy/prevodni-ceny-v-cr-aktualne-a-pro-rok-2024-pristup-financni-spravy-a-rozsah-dokumentace-2/?termin=340895"
                target="_blank"
              >
                Školení Převodní ceny v ČR aktuálně a pro rok 2024 – přístup
                finanční správy a rozsah dokumentace – Vzdělávání VOX
              </a>
            </p>

            <p>
              <b>Termín: </b>30.05.2024 / 09.00 – 12.00 / webinář
            </p>
            <p>
              <p>
                <b>Lektor: </b>Lukáš Eisenwort
              </p>
              <div className="PrednaskyLektor wrapper">
                <div>
                  <b>Organizátor: </b>
                  <img
                    className="PartnerLogo"
                    src="/images/konference/partners/vox.png"
                  ></img>
                </div>
                <div>
                  <p>
                    Využijte{' '}
                    <span style={{ color: 'red', fontWeight: 'bold' }}>
                      slevu ve výši 20 %
                    </span>{' '}
                    ze základní ceny bez DPH na tento seminář. Doplňte heslo{' '}
                    <span style={{ color: 'red', fontWeight: 'bold' }}>
                      „EKP“
                    </span>{' '}
                    do pole „Chci přidat důležitou poznámku“ v e-shopu při
                    objednávání.
                  </p>
                </div>
              </div>
            </p>

            {/* ZDE */}
          </div>
          <div className="PrednaskyLektor col-xl-2 col-sm-2 col-12 wrapper">
            <img src="/images/konference/lectors/lukas_eisenwort.png"></img>
          </div>
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <p>
              <b>Anotace:</b>
            </p>
            <p>
              Seminář je určen zejména finančním ředitelům, finančním manažerům,
              hlavním účetním a kontrolorům ve finančních odděleních.
              <li>
                zákonný rozsah dokumentace k převodním cenám v ČR – novelizované
                pokyny,
              </li>
              <li>
                analýza transakcí v podniku a metody stanovení převodní ceny pro
                každou z nich,
              </li>
              <li>arm´s lenght test a benefit test,</li>
              <li>
                srovnávací analýza – nejde pouze o benchmarkové srovnání s
                nezávislými podniky,
              </li>
              <li>
                finanční správa – jaká využívá data k srovnávacím analýzám,
              </li>
              <li>
                jak správně vyplnit přílohu k daňovému přiznání „Přehled
                transakcí se spojenými osobami“,
              </li>
              <li>specifika u různých druhů činností,</li>
              <li>aktuální judikatura k převodním cenám.</li>
            </p>
          </div>
        </div>
      </div>

      {/* --------------- konec 3. přednáška --------------- */}

      {/* ------------------ 4. přednáška ------------------ */}

      <div className="PrednaskyKonference--Wrapper container">
        <div className="row">
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <h2>Svěřenské fondy</h2>
            <p>
              <b>Link: </b>
              <a
                className="PrednaskyKonference--Link"
                href="https://www.certifikace-ucetnich.cz/sverenske-fondy"
                target="_blank"
              >
                Svěřenské fondy (certifikace-ucetnich.cz)
              </a>
            </p>

            <p>
              <b>Termín: </b>04.06.2024 / 09.00–12.00 / webinář
            </p>
            <p>
              <p>
                <b>Lektor: </b>Jan Kotala
              </p>
              <div className="PrednaskyLektor wrapper">
                <div>
                  <b>Organizátor: </b>
                  <img
                    className="PartnerLogo"
                    src="/images/konference/partners/institut.png"
                  ></img>
                </div>
              </div>
            </p>

            {/* ZDE */}
          </div>
          <div className="PrednaskyLektor col-xl-2 col-sm-2 col-12 wrapper">
            <img src="/images/konference/lectors/jan_kotala.png"></img>
          </div>
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <p>
              <b>Anotace:</b>
            </p>
            <p>
              Předání rodinného majetku představuje zásadní životní fázi každé
              rodiny, které se podařilo nashromáždit určité bohatství. Dobře
              nastavené předání majetku může celou rodinu spojit a předávané
              hodnoty ochránit. Bez přípravy však hrozí, a často také nastávají
              značné konflikty, které ohrožují jak rodinu, tak dané majetkové
              hodnoty (např. spory mezi společníky či spoluvlastníky). Pro
              předání majetku je též zásadní daňový rámec, který nelze při
              plánování mezigeneračního transferu bohatství opominout.
            </p>
          </div>
        </div>
      </div>

      {/* --------------- konec 4. přednáška --------------- */}

      {/* ------------------ 5. přednáška ------------------ */}

      <div className="PrednaskyKonference--Wrapper container">
        <div className="row">
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <h2>Optimalizace nákladů na zaměstnance</h2>
            <p>
              <b>Link: </b>
              <a
                className="PrednaskyKonference--Link"
                href="https://www.notiavzdelavani.cz/webinar/webinar-optimalizace-nakladu-na-zamestnance-2024-11-6-2024.htm"
                target="_blank"
              >
                https://www.notiavzdelavani.cz/webinar/webinar-optimalizace-nakladu-na-zamestnance-2024-11-6-2024.htm
              </a>
            </p>

            <p>
              <b>Termín: </b>11. 06. 2024 / 09.00–10.30 / konference
            </p>
            <p>
              <p>
                <b>Lektor: </b>Jan Tecl
              </p>
              <div className="PrednaskyLektor wrapper">
                <div>
                  <b>Organizátor: </b>
                  <img
                    className="PartnerLogo"
                    src="/images/konference/partners/notia.png"
                  ></img>
                </div>
              </div>
            </p>

            {/* ZDE */}
          </div>
          <div className="PrednaskyLektor col-xl-2 col-sm-2 col-12 wrapper">
            <img src="/images/konference/lectors/jan_tecl.png"></img>
          </div>
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <p>
              <b>Anotace:</b>
            </p>
            <p>
              Tento webinář zaměřený na optimalizaci nákladů na zaměstnance se
              zabývá problematikou zdanitelných a nezdanitelných náhrad mzdy,
              zaměstnaneckými benefity z pohledu zákona o daních z příjmů,
              aktuálním pohledem na agenturní zaměstnávání, a švarcsystém v
              aktuální judikatuře.
            </p>
          </div>
        </div>
      </div>

      {/* --------------- konec 5. přednáška --------------- */}

      {/* ------------------ 6. přednáška ------------------ */}

      <div className="PrednaskyKonference--Wrapper container">
        <div className="row">
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <h2>
              DPH rizika v e-shopech (nákup, vedlejší náklady, prodej v ČR,
              zmínit i one stop shop?)
            </h2>
            <p>
              <b>Link: </b>
              <a
                className="PrednaskyKonference--Link"
                href="https://obchod.wolterskluwer.cz/cz/dph-rizika-v-e-shopech.p8225.html"
                target="_blank"
              >
                DPH rizika v e-shopech (wolterskluwer.cz)
              </a>
            </p>

            <p>
              <b>Termín: </b>12.06.2024 / 09.00–12.00 / webinář
            </p>
            <p>
              <p>
                <b>Lektor: </b>Jan Kotala
              </p>
              <div className="PrednaskyLektor wrapper">
                <div>
                  <b>Organizátor: </b>
                  <img
                    className="PartnerLogo"
                    src="/images/konference/partners/wolters.png"
                  ></img>
                </div>
              </div>
            </p>

            {/* ZDE */}
          </div>
          <div className="PrednaskyLektor col-xl-2 col-sm-2 col-12 wrapper">
            <img src="/images/konference/lectors/jan_kotala.png"></img>
          </div>
          <div className="col-xl-5 col-sm-5 col-12 wrapper">
            <p>
              <b>Anotace:</b>
            </p>
            <p>
              Webinář je určen zejména majitelům, provozovatelům, finančním
              ředitelům a účetním e-shopů. Máte e-shop a prodáváte zboží v ČR.
              Chcete rozšířit okruh zákazníků a prodávat zboží i zákazníkům z EU
              – plánujete zboží zasílat přes DHL? Potom si nenechte ujít další
              praktický webinář, který bude na problematiku zaměřen.
              <li>Co mám v oblasti daní dělat?</li>
              <li>Proč musím řešit DPH při prodeji zboží zákazníkům do EU</li>
              <li>Povinná registrace k DPH ve státech příjemců zboží</li>
              <li>One-stop-shop – jednotná registrace k DPH v EU</li>
              <li>
                One-stop-shop v praxi (sazba, přiznání, platby a jiná
                administrativa)
              </li>
              <li>Prodej zboží přes Amazon.</li>
            </p>
          </div>
        </div>
      </div>
      {/* --------------- konec 6. přednáška --------------- */}
    </Layout>
  )
}

export default PrednaskyAKonference

export const pageQuery = graphql`
  query PrednaskyAKonference {
    page: markdownRemark(
      frontmatter: { slug: { eq: "o-nas/prednasky-konference" } }
    ) {
      ...Meta
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
