export const Index = {
  budouci: [],

  uplynule: [
    {
      title:
        'DoToho | Digitalizace a automatizace finanční účtárny podniků | 30.05.2023'
    },
    {
      title:
        'Wolters Kluwer | Daňové kontroly – jak se připravit a jak jimi projít | 16.05.2023'
    },
    {
      title:
        'DoToho | Business inteligence a finanční reporting v e-shopech | 02.05.2023'
    },
    {
      title:
        'DoToho | Náklady na marketing z daňového a účetního pohledu | 18.04.2023'
    },
    {
      title:
        'Forum Media | Daňové kontroly – jak se připravit a jak jimi projít | 11.04.2023'
    },

    {
      title: 'AMSP ČR | Odměňování jednatelů a společníků ve firmě | 16.03.2023'
    },
    {
      title:
        'EKP a 1. VOX | Daně a účetnictví u developerů a vlastníků nemovitostí aktuálně a pro rok 2023 | 15.03.2023'
    },
    {
      title:
        '1. VOX | Náklady na marketing z daňového a účetního pohledu | 01.03.2023'
    },
    {
      title:
        'Německo-česká obchodní komora | Optimalizace nákladů na zaměstnance v roce 2023 | 28.02.2023'
    },
    {
      title:
        'AMSP ČR | Optimalizace nákladů na zaměstnance v roce 2023 | 16.02.2023'
    },
    {
      title: 'VNL Chambers | Účetní a daňové novinky | 11.01.2023'
    },
    {
      title:
        'VOX | Daně a účetnictví u developerů a vlastníků nemovitostí | 12.12.2022'
    },
    {
      title: 'AMSP | Daňový balíček 2023 | 1.12.2022'
    },
    {
      title: 'Wolters Kluwer | TAXForum 2023, online konference | 29.11.2022'
    },
    {
      title:
        'VOX | Optimalizace mzdových nákladů pro personality a mzdové účetní | 24.11.2022'
    },
    {
      title: 'VOX | Výroba a ocenění zásob v účetnictví | 21.11.2022'
    },
    {
      title:
        'Wolters Kluwer | Novinky a obtížné situace v daních a účetnictví developerů a nemovitostních společností (WEBINÁŘ) | 31.05.2022'
    },
    {
      title:
        'VOX |  Náklady na marketing z daňového a účetního pohledu (prezenční výuka) | 12.05.2022'
    },
    {
      title:
        'VOX |  Náklady na marketing z daňového a účetního pohledu (on-line výuka) | 12.05.2022'
    },
    {
      title:
        'Wolters Kluwer | Kryptoaktiva – těžba a obchodování s nimi v daních a účetnictví (WEBINÁŘ) | 28.04.2022'
    },
    {
      title:
        'VOX |  Daně a účetnictví u developerů a vlastníků nemovitostí v roce 2022 (prezenční výuka) | 08.03.2022'
    },
    {
      title:
        'VOX |  Daně a účetnictví u developerů a vlastníků nemovitostí v roce 2022 (on-line výuka) | 08.03.2022'
    }
    // {
    //   title: 'Wolters Kluwer |  Konference Tax Forum 2022 | 23.11.2021',
    //   link: 'https://konference.dauc.cz/taxforum/'
    // },
    // {
    //   title:
    //     'Vysílání zahraničních zaměstnanců do ČR a českých zaměstnanců do zahraničí |  11.11.2021',
    //   link: 'https://konference.dauc.cz/taxforum/'
    // },
    // {
    //   title:
    //     'VOX | Převodní ceny v ČR v roce 2021 – aktuální přístup finanční správy a rozsah dokumentace | 21.10.2021',
    //   link:
    //     'http://vox.cz/kurzy/prevodni-ceny-v-cr-v-roce-2021-aktualni-pristup-financni-spravy-a-rozsah-dokumentace/?termin=41528'
    // },
    // {
    //   title:
    //     'VOX | Náklady na marketing z daňového a účetního pohledu | 22.10.2021',
    //   link:
    //     'http://vox.cz/kurzy/naklady-na-marketing-z-danoveho-a-ucetniho-pohledu/?termin=41526'
    // },
    // {
    //   title: 'HK Jihlava | DPH ve výrobních podnicích | 19.10.2021',
    //   link:
    //     'https://www.hkjihlava.cz/akce/667-seminar-ucetni-a-danova-specifika-vyrobnich-podniku'
    // },
    // {
    //   title:
    //     'Wolters Kluwer | Svěřenské fondy - právní a daňové dopady | 19.10.2021',
    //   link:
    //     'https://obchod.wolterskluwer.cz/cz/sverensky-fond-jako-ochrana-majetku-v-praxi-zkusenosti-spravcu-a-danove-dopady-fungovani-fondu-webinar.p6327.html'
    // },
    // {
    //   title: 'HK Jihlava | Roční účetní závěrka a příprava na ni | 20.09.2021',
    //   link:
    //     'https://www.hkjihlava.cz/akce/667-seminar-ucetni-a-danova-specifika-vyrobnich-podniku'
    // },
    // {
    //   title:
    //     'Wolters Kluwer | Daně u developerů a majitelů nemovitostí v roce 2021 | 09.09.2021',
    //   link:
    //     'https://obchod.wolterskluwer.cz/cz/dane-u-developeru-a-majitelu-nemovitosti-v-roce-2021-webinar.p6225.html'
    // },
    // {
    //   title: 'HK Jihlava | Výroba a ocenění zásob | 02.09.2021',
    //   link:
    //     'https://www.hkjihlava.cz/akce/667-seminar-ucetni-a-danova-specifika-vyrobnich-podniku'
    // },
    // {
    //   title: 'VOX | Převodní ceny v ČR v roce 2021 | 09.08.2021',
    //   link:
    //     'https://www.vox.cz/danove-a-ucetni-kurzy/kurz-prevodni-ceny-v-cr-v-roce-2021-aktualni-pristup-financni-spravy-a-rozsah-dokumentace-0001.htm'
    // },
    // {
    //   title:
    //     'VOX | Náklady na marketing z daňového a účetního pohledu | 14.06.2021',
    //   link:
    //     'https://www.vox.cz/danove-a-ucetni-kurzy/kurz-naklady-na-marketing-z-danoveho-a-ucetniho-pohledu-0004.htm'
    // },
    // {
    //   title:
    //     'Česko-německá obchodní a průmyslová komora | Daňové změny od roku 2021 | 1.6.2021',
    //   link:
    //     'https://tschechien.ahk.de/cz/terminy/terminy-detail/online-seminar-danove-zmeny-v-roce-2021'
    // },
    // {
    //   title:
    //     'Wolters Kluwer | Daně u developerů a majitelů nemovitostí v roce 2021 | 12.05.2021',
    //   link:
    //     'https://obchod.wolterskluwer.cz/cz/dane-u-developeru-a-majitelu-nemovitosti-v-roce-2021-webinar.p6149.html'
    // },
    // {
    //   title:
    //     'Wolters Kluwer | Optimalizace mzdových nákladů v roce 2021 | 05.05.2021',
    //   link:
    //     'https://obchod.wolterskluwer.cz/cz/optimalizace-mzdovych-nakladu-v-roce-2021-benefity-vydajove-pausaly-a-ostatni-zvyhodnene-prijmy.p6117.html'
    // },
    // {
    //   title: 'VOX | Převodní ceny u úvěrů a zápůjček | 03.05.2021',
    //   link:
    //     'https://www.vox.cz/kurzy-ekonomiky-a-financi/kurz-prevodni-ceny-u-uveru-a-zapujcek-v-roce-2021-0001.htm'
    // },
    // {
    //   title:
    //     'Wolters Kluwer | Stravenkový paušál – první zkušenosti | 07.04.2021',
    //   link: ''
    // },
    // {
    //   title:
    //     'Wolters Kluwer | DPH u pronájmu nemovitostí v roce 2021 | 15.03.2021',
    //   link: ''
    // },
    // {
    //   title: 'Wolters Kluwer | Účetní závěrka 2020 | 04.03.2021',
    //   link: ''
    // }
  ]
}
export default Index
